import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

import pantheon7 from "../../logos/photos/PANTHEON Scenarios BlogPost.jpg"


export default function Blog5() {

  
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
              <h3 style={{color:'#e95d04'}}>
              PANTHEON: Advancing Disaster Management Through Smart City Digital Twins
             </h3>
             <p>As climate change intensifies the frequency and complexity of natural and man-made hazards,
disaster risk management systems must evolve. The <b>PANTHEON project</b> responds to this
need by integrating <b>Smart City Digital Twins (SCDTs) simulations, UAV technologies,</b> and
<b>multi-source data</b> into a single decision-support platform. In this blog post, we'll dive deeper
into the technologies and methodology of designing four detailed disaster scenarios grounded in
user needs from Greece and Austria. The result is a platform that offers scalable, adaptable,
and ethically aligned tools for modern crisis preparedness and response.
         
                </p>
                <h4>Grounded in Real Needs: A Participatory Design Approach</h4>
                <p>PANTHEON’s foundation lies in a <b>participatory design methodology</b>. Through stakeholder
workshops and iterative feedback loops, the team worked with emergency responders and civil
protection agencies in <b>Attica (Greece)</b> and <b>Vienna (Austria)</b>. These sessions identified local
hazard priorities, system expectations, and data constraints. This process ensured the
platform’s relevance, usability, and legal/ethical compliance.</p>
<p>The methodology followed a three-phase cycle:</p>
<ul style={{marginBottom:'20px'}}>
  <li><b>✓ Requirements Elicitation</b>: Captured domain-specific hazards and user workflows.</li>
  <li><b>✓ Scenario and System Co-Design</b>: Translated needs into simulation parameters and
  interface requirements.</li>
  <li><b>✓ Pilot Preparation</b>: Aligned user requirements with high-level architecture and interface
  design in anticipation of testing.</li>
</ul>
<h4>Scenario 1: Wildfire in Attica</h4>
<p>Wildfires in Attica are frequent and devastating. PANTHEON models wildfire propagation using
<b>terrain, vegetation, and weather data</b>, integrating <b>UAV swarms</b> for aerial monitoring. The
SCDT simulates fire spread and visualises cascading effects (e.g., road blockages and
evacuation bottlenecks). Users can simulate resource allocation scenarios, e.g., how drone-
assisted surveillance changes containment strategies.</p>
<p><b>Impact: </b>Strengthens operational readiness and inter-agency coordination.</p>

<h4>Scenario 2: Earthquake in Attica</h4>
<p>Focusing on <b>planning and resilience</b>, this scenario simulates seismic events and infrastructure
impacts. It includes <b>building impact models, aftershock forecasting,</b> and <b>emergency route
viability</b>. The system enables testing user response and coordination under pressure.</p>
<p><b>Impact: </b>Supports planning, response, and predictive analytics to reduce delays and casualties.</p>

<h4>Scenario 3: Heatwave in Vienna</h4>
<p>This scenario models prolonged heatwaves and their effects on health systems, especially for
vulnerable populations. Using <b>IoT environmental sensors</b> and population data, PANTHEON
forecasts the burden on emergency services. Simulations include <b>ambulance routing delays</b>
and hospital <b>triage overflow.</b></p>
<p><b>Impact: </b>Enables urban planners and health agencies to model early warning triggers, resource
pre-positioning, and plan mitigation strategies.</p>

<h4>Scenario 4: Cyber-Terrorism in Vienna</h4>
<p>A novel inclusion, this scenario simulates <b>coordinated cyberattacks</b> on Vienna’s critical
infrastructure—e.g., energy, water, and traffic control. It focuses on cascading physical effects
caused by virtual attacks. The SCDT visualises secondary impacts, such as <b>communications
failures</b> or <b>public safety incidents</b> due to traffic light manipulation or data loss.</p>
<p><b>Impact: </b>Enhances training for integrated cyber-physical response across first responder teams.</p>

<h4>Technology Stack: Drones, Digital Twins, and Dynamic Data</h4>
<p>All scenarios are underpinned by a tightly integrated stack:</p>
<ul style={{marginBottom:'20px'}}>
  <li><b>✓ Smart City Digital Twins</b> for real-time, spatially-aware simulations.</li>
  <li><b>✓ UAV swarms</b>for dynamic data capture and aerial assessments.
 </li>
  <li><b>✓ Weather and IoT sensors</b>, satellite feeds, and urban infrastructure data streams. </li>
  <li><b>✓ User-driven UI/UX design</b> ensuring non-technical stakeholders can simulate, observe,
  and act within the digital twin. </li>
</ul>
<p>Each simulation is configurable, enabling scenario creation, adaptive timelines, and
stakeholder-specific views. The system includes privacy-preserving data governance aligned
with GDPR and integrates community organisations into the visual and operational layers of the
SCDT.</p>
<h4>What’s Next: From Scenario to Pilot</h4>
<p>With scenario design complete, the next phase involves full-scale pilots in both regions.
Evaluation will follow a cyclical model:</p>
<ul style={{marginBottom:'20px'}}>
  <li><b>✓ Scenario testing </b>with real users.</li>
  <li><b>✓ Feedback collection</b> and functional refinement.</li>
  <li><b>✓ Architecture alignment</b> to integrate new requirements into system design.</li>
</ul>
<h4>Conclusion</h4>
<p>PANTHEON provides a modular, user-centred, and technologically advanced approach to
disaster risk management. It replaces fragmented response systems with a unified platform that
simulates real-time crises, enables preparedness, and guides coordinated action. Through the
tight integration of digital twins, UAVs, and human-centred design, PANTHEON sets a new
standard for managing disasters in complex urban environments.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={pantheon7}
                  style={{borderRadius:'10px',width:'400px'}}
                  alt="about us"
                  className="img-fluid"
                />
               
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
